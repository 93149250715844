import { useEffect, useState, useRef } from "react";
import { supabase } from "../lib/api";
import RecoverPassword from "../components/RecoverPassword";
// import lockup svg from assets/lockup.svg
import { useAuth } from "../components/Auth";
import { Link, Navigate } from "react-router-dom";
import lockup_white from "../assets/lockup-white.svg";
 
const HomePage = () => {
    let auth = useAuth();

    const [confettiActive, setConfettiActive] = useState(false);
    const [recoveryToken, setRecoveryToken] = useState(null);
    const [todos, setTodos] = useState([]);
    const [loading, setLoading] = useState(true);

    const newTaskTextRef = useRef();
    const [errorText, setError] = useState("");

    useEffect(() => {
        /* Recovery url is of the form
         * <SITE_URL>#access_token=x&refresh_token=y&expires_in=z&token_type=bearer&type=recovery
         * Read more on https://supabase.com/docs/reference/javascript/reset-password-email#notes
         */
        let url = window.location.hash;
        let query = url.slice(1);
        let result = {};

        query.split("&").forEach((part) => {
            const item = part.split("=");
            result[item[0]] = decodeURIComponent(item[1]);
        });

        if (result.type === "recovery") {
            setRecoveryToken(result.access_token);
        }

        fetchTodos().catch(console.error);
    }, []);

    const fetchTodos = async () => {
        let { data: todos, error } = await supabase
            .from("todos")
            .select("*")
            .order("id", { ascending: false });
        if (error) console.log("error", error);
        else {
            setTodos(todos);
            setLoading(false);
        }
    };

    // run fetchTodos every second
    useEffect(() => {
        const interval = setInterval(() => {
            fetchTodos();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    // trigger confetti when you have to-dos, but they're all done
    useEffect(() => {
        setConfettiActive(!loading && todos.length > 0 && todos.every((todo) => todo.completed_at));
    }, [todos]);

    const deleteTodo = async (id) => {
        try {
            await supabase.from("todos").delete().eq("id", id);
            setTodos(todos.filter((x) => x.id !== id));
        } catch (error) {
            console.log("error", error);
        }
    };

    const deleteAllTodos = async () => {
        try {
            await supabase
                .from("todos")
                .delete()
                .eq("user_id", auth.session.user.id);
            setTodos([]);
        } catch (error) {
            console.log("error", error);
        }
    };

    const addTodo = async () => {
        let taskText = newTaskTextRef.current.value;
        let task = taskText.trim();
        if (task.length <= 3) {
            setError("Task length should be more than 3!");
        } else {
            console.log(auth.session.user.id);
            let { data: todo, error } = await supabase
                .from("todos")
                .insert({ task, user_id: auth.session.user.id })
                .select()
                .single();
            if (error) setError(error.message);
            else {
                setTodos([todo, ...todos]);
                setError(null);
                newTaskTextRef.current.value = "";
            }
        }
    };

    const handleLogout = async () => {
        auth.signOut();
    };

    return recoveryToken ? (
        <RecoverPassword
            token={recoveryToken}
            setRecoveryToken={setRecoveryToken}
        />
    ) : (
        // redirect to apps page
        <Navigate to="/apps" />
    );
};

export default HomePage;
