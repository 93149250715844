import { useState, useEffect } from "react";
import { supabase } from "../lib/api";
import { useAuth } from "../components/Auth";
import { useParams } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/20/solid";
import ReactTimeAgo from "react-time-ago";
import AppOnboarding from "./AppOnboarding.js";
import AppShell from "../components/AppShell";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SingleAppPage() {
  let auth = useAuth();
  let params = useParams();

  let [appEvents, setAppEvents] = useState([]);
  let [app, setApp] = useState(null);
  let [shouldShowOnboarding, setShouldShowOnboarding] = useState(false);

  // get app name and icon
  useEffect(() => {
    supabase
      .from("app")
      .select()
      .eq("id", params.appId)
      .maybeSingle()
      .then((data) => {
        setApp(data.data);
      });
  }, [params.appId]);

  // if app is not null, get events for this app
  useEffect(() => {
    if (app) {
      supabase
        .from("event")
        .select()
        .eq("app_id", app.id)
        .eq("user_id", auth.session.user.id)
        .then((data) => {
          console.log(data);
          // sort events in chronological order
          setAppEvents(data.data.sort((a, b) => b.created_at > a.created_at));
          if (data.data.length === 0) {
            setShouldShowOnboarding(true);
          }
        });
    }
  }, [app, auth.session.user.id]);

  return (
    <>
      <AppShell
        title={
          shouldShowOnboarding
            ? `Set up ${app.name} history`
            : app && `Your ${app.name} history`
        }
      >
        <div>
          <div className="p-4">
            {/* App name and icon */}

            {shouldShowOnboarding ? (
              <AppOnboarding app={app}/>
            ) : (
              <>
                {app && (
                  <div className="flex">
                    <div className="flex items-center gap-4 mb-6 rounded-lg">
                      <img
                        className="h-10 w-10 rounded-lg shadow"
                        src={app.image_url}
                        alt=""
                      />
                      <div>
                        <p className="truncate font-bold text-gray-900 text-xl">
                          {app.name}
                        </p>
                        {appEvents && appEvents.length > 0 && (
                          <p className="text-xs">
                            Last opened{" "}
                            <ReactTimeAgo
                              date={appEvents[0].created_at}
                              locale="en-US"
                            />
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <ul className="mt-4">
                  {appEvents.map((event, eventIdx) => (
                    <li key={event.id}>
                      <div className="relative pb-8">
                        {eventIdx !== appEvents.length - 1 ? (
                          <span
                            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                            aria-hidden="true"
                          />
                        ) : null}
                        <div className="relative flex space-x-3">
                          <div>
                            <span
                              className={classNames(
                                "h-8 w-8 bg-indigo-500 rounded-full flex items-center justify-center ring-8 ring-white"
                              )}
                            >
                              <EyeIcon
                                className="h-5 w-5 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                          <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                            <div>
                              <p className="text-sm text-gray-500">
                                You opened {app.name}
                              </p>
                            </div>
                            <div className="whitespace-nowrap text-right text-sm text-gray-500">
                              <ReactTimeAgo
                                date={event.created_at}
                                locale="en-US"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </AppShell>
    </>
  );
}
