import React, { useEffect, useState } from "react";
import terms from "../assets/terms.md";
import ReactMarkdown from "react-markdown";
import AppShell from "../components/AppShell";
import Spinner from "../pages/Spinner";
import remarkGfm from "remark-gfm";
import rehypeSlug from "rehype-slug";
import rehypeAutolinkHeadings from "rehype-autolink-headings"
export default function TermsOfUsePage() {
  const [markdown, setMarkdown] = useState(null);

  useEffect(() => {
    fetch(terms)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return markdown ? (
    <AppShell noNav>
      <ReactMarkdown children={markdown} rehypePlugins={[rehypeSlug, rehypeAutolinkHeadings]} remarkPlugins={[remarkGfm]} className="prose" />
    </AppShell>
  ) : (
    <Spinner />
  );
}
