import React from "react";

import { ArrowDownOnSquareIcon } from "@heroicons/react/20/solid";
import APIKeyDisplay from "../components/APIKeyDisplay";
import { useEffect } from "react";
import { useAuth } from "../components/Auth";
import { supabase } from "../lib/api";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AppOnboarding({ app }) {
  let [apiKey, setApiKey] = React.useState(null);
  let auth = useAuth();

  function resetApiKey() {
    supabase.functions
      .invoke("reset-api-key", {
        body: {
          app_id: app.id,
        },
      })
      .then((data) => {
        setApiKey(data.data.api_key);
      });
  }
  useEffect(() => {
    supabase
      .from("app_config")
      .select("*")
      .eq("user_id", auth.session.user.id)
      .eq("app_id", app.id)
      .maybeSingle()
      .then((data) => {
        if (data.data === null) {
          resetApiKey();
        } else {
          setApiKey(data.data.api_key);
        }
      });
  }, []);

  const steps = [
    <>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Download the Apple Shortcut
      </h3>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">
        A shortcut is a small program that you can run on your iPhone, iPad, or
        Mac to do things like send a text message or add a calendar event. Here,
        we'll use a shortcut to contact Decrease each time you open {app.name}.
      </p>
      <a href="https://www.icloud.com/shortcuts/ab253f8e0fd24867a46cb418f0a91824">
      <button
        type="button"
        className="mt-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      
      >
        <ArrowDownOnSquareIcon
          className="-ml-1 mr-3 h-5 w-5"
          aria-hidden="true"
        />
        Download shortcut
      </button>
      </a>
    </>,
    <>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Set up shortcut
      </h3>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">
        When you open the shortcut for the first time, it will ask you for an
        API key, shown below.
      </p>
      <div className="w-full sm:w-2/3 md:w-1/2 mt-4">
        <APIKeyDisplay
          className="w-64"
          apiKey={apiKey}
          onRegenerate={() => {
            resetApiKey();
          }}
        />
      </div>
    </>,
    <>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Create an Automation
      </h3>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">
        Once you've set up the shortcut, you need to set it to trigger each time
        you open {app.name}.
        <ul className="list-disc ml-4 mt-4">
          <li>
            Open the Shortcuts app, then click the plus button to create a new
            automation.
          </li>
          <li>Choose "Personal Automation", then "App".</li>
          <li>Select "{app.name}", then "Is Opened".</li>
          <li>
            Choose "Run Shortcut" and select the shortcut you just downloaded.
          </li>
          <li>Disable "Ask Before Running" and "Notify When Run".</li>
        </ul>
      </p>
    </>,
    <>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Trigger the automation
      </h3>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">
        Open {app.name}. You may see a permission prompt asking for permission
        to connect to a server ending in "supabase.com". This is normal and
        required for the shortcut to work, so choose "Always Allow".
      </p>
    </>
  ];
  return (
    <>
      <ul role="list" className="divide-y divide-gray-200">
        {steps.map((step) => (
          <li key={step.id} className="py-4">
            {step}
          </li>
        ))}
      </ul>
    </>
  );
}
