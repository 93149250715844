import { useState, useEffect } from "react";
import { supabase } from "../lib/api";
import { useAuth } from "../components/Auth";
import { Link } from "react-router-dom";

export default function AppsPage() {
  const [integrations, setIntegrations] = useState([]);
  const [apps, setApps] = useState([]);

  let auth = useAuth();

  useEffect(() => {
    // get all apps keys from api_key for this user; join with apps table to get app name and icon
    supabase
      .from("api_key")
      .select(
        `
          api_key,
          app:app_id (name, image_url)
        `
      )
      .eq("user_id", auth.session.user.id)
      .then((data) => {
        setIntegrations(data.data);
      });
  }, [auth.session.user.id]);

  useEffect(() => {
    // get all apps that are not already integrated
    supabase
      .from("app")
      .select()
      .then((data) => {
        setApps(data.data);
      });
  }, []);

  return (
    <>
      <div>
        <div className="flow-root p-4">
          <ul className="-my-5 divide-y divide-gray-200">
{/*             {integrations.map((integration) => (
              <li key={integration.app.name} className="py-4">
                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-lg shadow"
                      src={integration.app.image_url}
                      alt=""
                    />
                  </div>
                  <div className="min-w-0 flex-1">
                    <p className="truncate text-sm font-medium text-gray-900">
                      {integration.app.name}
                    </p>
                    <p className="truncate text-sm text-gray-500">
                      {integration.api_key}
                    </p>
                  </div>
                </div>
              </li>
            ))} */}
          </ul>

          {/* show a side-by-side grid of apps that you can click to add */}

          <div className="rounded p-4 mt-8">{/* 
            <h1 className="text-xl font-bold ml-4 mb-4">Your apps</h1> */}
            <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-10 xl:grid-cols-12">
              {apps.map((app) => (
                <div
                  key={app.name}
                  className="col-span-1 flex items-center justify-center p-3"
                >
                  <Link to={`/apps/${app.id}`}>
                  <button className="hover:scale-110 shadow-md rounded-md transition-all overflow-hidden">
                    <img
                    className=""
                      src={app.image_url}
                      alt=""
                    />
                  </button>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
