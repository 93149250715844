import React, { useEffect } from "react";
import { supabase } from "../lib/api";
import UsageStats from "./UsageStats.js";
export default function StripeTest() {
  let [usageSummaries, setUsageSummaries] = React.useState(null);

  function createPortalSession(env = "test") {
    supabase.functions
      .invoke("create-portal-session", { headers: { "X-Environment": env } })
      .then((response) => {
        if (response.error) {
          alert(response.error.message);
          return;
        }

        const url = response.data.url;
        window.location.href = url;
      });
  }

  function createCheckoutSession(env = "test") {
    supabase.functions
      .invoke("create-checkout-session", { headers: { "X-Environment": env } })
      .then((response) => {
        if (response.error) {
          alert(response.error.message);
          return;
        }

        const url = response.data.url;
        window.location.href = url;
      });
  }

  return (
    <div className="flex flex-col gap-4">

<div className="rounded shadow p-4 bg-indigo-100">
      <h1>Stripe (production environment)</h1>
      <button
        onClick={createPortalSession.bind(this, "prod")}
        className="mt-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Open billing portal (prod)
      </button>
      <button
        onClick={createCheckoutSession.bind(this, "prod")}
        className="mt-4 ml-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Add a card (prod)
      </button>
      </div>

      <div className="rounded shadow p-4 bg-gray-200">
      <h1>Stripe (test environment)</h1>
      <button
        onClick={createPortalSession.bind(this, "test")}
        className="mt-4 inline-flex items-center rounded-md border border-transparent bg-neutral-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Open billing portal (test)
      </button>

      <button
        onClick={createCheckoutSession.bind(this, "test")}
        className="mt-4 ml-4 inline-flex items-center rounded-md border border-transparent bg-neutral-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Add a card (test)
      </button>
      </div>
      {/* 
      <UsageStats /> */}
    </div>
  );
}
