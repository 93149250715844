import { ProvideAuth, AuthGuard } from "./components/Auth.js";
import { Routes, Route } from "react-router-dom";
import AppShell from "./components/AppShell";
import LoginPage from "./pages/LoginPage";
import AppHome from "./pages/AppHome";
import SettingsPage from "./pages/SettingsPage";
import AppsPage from "./pages/AppsPage";
import SingleAppPage from "./pages/SingleAppPage";
import TermsOfUsePage from "./pages/TermsOfUsePage.js";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage.js";

function App() {
  return (
    <ProvideAuth>
      <Routes>
        <Route
          path="/"
          element={
            <AuthGuard home>
              <AppShell>
                <AppHome />
              </AppShell>
            </AuthGuard>
          }
        />

        <Route path="/terms" element={<TermsOfUsePage />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route
          path="/settings"
          element={
            <AuthGuard>
              <AppShell title="Settings">
                <SettingsPage />
              </AppShell>
            </AuthGuard>
          }
        />

        <Route
          path="/apps/:appId"
          element={
            <AuthGuard>
              <SingleAppPage />
            </AuthGuard>
          }
        />
        <Route
          path="/apps"
          element={
            <AuthGuard>
              <AppShell title="Apps">
                <AppsPage />
              </AppShell>
            </AuthGuard>
          }
        />
        <Route
          path="/login"
          element={
            <AppShell noNav>
              <LoginPage />
            </AppShell>
          }
        />
      </Routes>
    </ProvideAuth>
  );
}

export default App;
