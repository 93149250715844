import { useState, useEffect } from "react";
import APIKeyDisplay from "../components/APIKeyDisplay";
import { supabase } from "../lib/api";
import { useAuth } from "../components/Auth";
import StripeTest from "../components/StripeTest.js";

export default function SettingsPage() {
  let auth = useAuth();

  return (
    <>
      <div className="flex flex-col gap-8 p-8 sm:p-4">
        <StripeTest />
      </div>
    </>
  );
}
